import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {topConnect} from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReputacionService {

constructor(
  private http: HttpClient) {}

  public getInfo(month: number,year: number,id: any,type: string): Observable<any>{
  	const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      //'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    if (id==='0'){
      type = 'sticker';
      id = localStorage.getItem('stickerId') ?? false;
    }
    return this.http.get(`${topConnect.backUrl}/reputation/${type}/${id}/${year}/${month}/`,{headers});
  }

}
