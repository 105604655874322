import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HistoricoService } from 'src/app/service/historico.service';
import { FilterService } from 'src/app/service/filter.service';
import { DrawStatsService } from 'src/app/service/draw-stats.service';
import { historico } from './historico.model';
import { Subscription, zip } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-historico',
  templateUrl: './historico.component.html',
  styleUrls: ['./historico.component.scss']
})
export class HistoricoComponent implements OnInit {
  public stats: historico;
  public drawStats: DrawStatsService;
  public customBrandSubscription: Subscription;
  public customDateSubscription: Subscription;
  public datesInfo: any[] = [];

  constructor(
    public router: Router,
    public historicoService: HistoricoService,
    private drawStatsService: DrawStatsService,
    private filter: FilterService
  ) {
    if (!localStorage.getItem('access_token')){
      this.router.navigate(['']);
    }
    this.drawStats = new DrawStatsService();
    this.stats = this.getStatsDefault();
  }

  ngOnInit(): void {
        this.customBrandSubscription = this.filter.customBrand.subscribe(brandId => {

          const historicoArray = [] as any;
          this.stats.historico = [];

            const date: any = this.getDatesInfo();
            const url = '?onlyMarca=1';
            for(const i in date){
              const month= date[i].month;
              const year = date[i].year;
              const newDataHistorico = this.historicoService.getInfo(month,year,brandId,"marca", url );
              historicoArray.push(newDataHistorico);
            }
            zip(...historicoArray).pipe(first()).subscribe((data)=>{
              const datos = data.flat();
               datos.map((datoss)=>{
                this.fillHistorico(datoss);
              });
            });

        });
  }
  ngOnDestroy(): void{
    this.customBrandSubscription.unsubscribe();
  }
  fillHistorico(data: any){
      const newHistorico={
      fecha:data.month,
      ano:data.year,
      criticas:data.rating,
      clasificacion:data.reviews,
      indirectas: data.busquedas.indirectas,
      directas: data.busquedas.directas,
      totalesBusquedas:data.busquedas.totales,
      maps: data.vistas.maps,
      busquedas: data.vistas.busquedas,
      totalesVistas: data.vistas.totales,
      telefono: data.acciones.telefono,
      web: data.acciones.web,
      indicaciones: data.acciones.indicaciones,
      totalesAcciones: data.acciones.totales,
      };
      this.stats.historico.push(newHistorico);
  }
  getDatesInfo(){

    const d = new Date();
    let month = d.getMonth();
    let year = d.getFullYear();
    this.datesInfo = [] as any;

    for(let i=1;i<13;i++,month--){
        if(month === 0){
            month = 12;
            year--;
        }
        this.datesInfo.push({
          month,
          year
        });
    }
    return this.datesInfo;
  }
  getStatsDefault(){
    return {
      historico:[],
    };
  }
}
