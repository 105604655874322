<nav class="navbar navbar-expand-lg">

  <div class="collapse navbar-collapse">
    <img class="logo" src="../assets/img/logoRia.png">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0 ml-2" style="margin-left: 20px;">
      
      <!-- <li class="nav-item ">
        <select class="form-control" style="width: 200px;" (change)="captureCompanySelect()">
          <option value="{{company._id}}">{{company.nombre}}</option>
        </select>
      </li> -->
 <!-- 
      <li class="nav-item ">
        <select class="form-control" style="width: 200px;margin-left: 20px;" [(ngModel)]="optionBrandSelect" (change)="captureBrandSelect()" >
          <option value="0" >{{'all-brands' | translate}}</option>
          <option value="{{brand._id}}" *ngFor="let brand of brands" >{{brand.nombre}}</option>
        </select>
      </li> -->

      <li class="nav-item ">
        <select class="form-control" style="width: 200px;margin-left: 20px;" (change)="captureStickerSelect()" >
          <option value="{{sticker._id}}" >{{sticker.name}}</option>
        </select>
      </li>
      
      <li class="nav-item dropdown">
          <select  style="width: 150px;" class="form-control" [(ngModel)]="optionDateSelect" id="datePanel" (change)="captureDateSelect()" >
            <option value="{{date.value}}" [selected]="date.value === optionDateSelect" *ngFor="let date of selectDates" >{{date.name | translate}} {{date.year}}</option>
          </select>
      </li>
   
      <form class="form-location">
          <input type="text" placeholder="Search for a location" class="form-control" style="width: 500px;margin-left: 20px;" matInput [formControl]="myControlLocations" [(ngModel)]="this.optionLocationObjectSelect" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (onSelectionChange)="captureLocationSelect(option)">
              {{option.codigo_tienda}} {{option.direccion}} {{option.label}}
            </mat-option>
          </mat-autocomplete>
      </form>
    </ul>  
  </div>

  <div class="btn-group">
    <a [ngClass]="drawStats.getClassButtonLocation(this.filter.getLocationCurrentValue())" class="btn return btn-md" (click)="goToGoogleMaps(locationsIds)">View in Google</a>
  </div>

  <div class="btn-group" [routerLink]="this.router.url">
    <button type="submit"[ngClass]="drawStats.getClassByRouter(this.router.url)" class="btn return btn-md" (click)="returnToPanel()">{{'go-to-panel' | translate}}</button>
  </div>

  <!-- <div class="btn-group">
    <button type="submit" class="btn logOut btn-primary btn-md" (click)="goHistory()">{{'historic' | translate}}</button>
  </div> -->

<!--  <div class="btn-group">
    <button type="submit" class="btn logOut btn-primary btn-md" (click)="goMap()">Mapa</button>
</div> -->

  <button class="btn idioma btn-info btn-md" type="submit" [ngClass]="drawStats.getLanguageEs(activeLang)" (click)="cambiarLenguaje('es')">Spanish</button>
  <button class="btn idioma btn-info btn-md" type="submit" [ngClass]="drawStats.getLanguageEn(activeLang)" (click)="cambiarLenguaje('en')">Ingles</button>

<div class="btn-group">
    <button type="submit" class="btn logOut btn-primary btn-md" (click)="logOut()">{{'logout' | translate}}</button>
</div>
</nav>
 