import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {topConnect} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class HistoricoService {

  constructor(
    private http: HttpClient) { }
    public getInfo(month: number,year: number,id: any,type: string,url: string): Observable<any>{

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      if (id ==='0'){
        type = 'empresa';
        id = localStorage.getItem('companyId')?? false;
        url = '';

      }

      return this.http.get(`${topConnect.histUrl}${type}/${id}/data/month/${month}/year/${year}${url}`,{headers:headers});
    }
}
