import { Component, OnInit } from '@angular/core';
import { DrawStatsService} from 'src/app/service/draw-stats.service';
import { LangMonthService } from 'src/app/service/lang-month.service';
import { FilterService } from 'src/app/service/filter.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.scss']
})
export class GlossaryComponent implements OnInit {
  drawStats: DrawStatsService;
  public customLangSubscription: Subscription;
  public activeLang = 'en';

  constructor(
    public langMonthService: LangMonthService,
    private filter: FilterService,
    private drawStatsService: DrawStatsService,
  ) { this.drawStats = new DrawStatsService();}

  ngOnInit(): void {

  }

}
