import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {topConnect} from '../../environments/environment';
/* eslint-disable */
@Injectable({
  providedIn: 'root'
})
export class StickerService {

  constructor(
    public http: HttpClient) { }
    public getStickerLogged(): Observable<any>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('access_token')
      });
      const stickerId: any = localStorage.getItem('stickerId') ?? false;
      return this.http.get(`${topConnect.apiUrl}stickers/${stickerId}`,{headers});
    }

    public postStickersId(){
      const stickerId: any = localStorage.getItem('stickerId')?? false;
      const params = {ids:[stickerId]};
  	const headers = new HttpHeaders({
      'Content-Type':'application/json',
      'Authorization': localStorage.getItem('access_token')
    });

  	return new Promise((resolve,rejected) => {
      const companyId: any = localStorage.getItem('companyId')?? false;
  		this.http.post<any>(topConnect.apiUrl+`/empresas/${companyId}/localidades/filter/`, params, {headers})
  		.subscribe(
  		        (data: any) => {
  		        	resolve(data);
  		            console.log('POST call successful value returned in body',data);
  		        },
  		        response => {
  		        	rejected(response);
  		            console.log('POST call in error', response);
  		        },
  		        () => {
  		            console.log('The POST observable is now completed.');
  		        });
  	});
    }
}
