<div class="cortina" id="cortina">
  <span class="spinner hidden" id="loaderSpinner"></span>
<app-navegador></app-navegador>
<div class="titulo">
  <p class="titulop text-center bold">Reviews</p>
</div>

<div id="canvas"><canvas id="myChart"></canvas></div>

<div class="container-fluid data-tipology">
  <div class="row">
    <div class="col-md p-3 Global">GLOBAL</div>
    <div class="col-md p-3 " *ngFor="let stat of stats.typologies">
      <div class="row">
        <div class="col-md-12 text-analisis">
          <img [src]='drawStats.getIcon(stat.name,stat.global.average)'> {{stat.name | translate}}
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center background-color" >
          <h1 class="top-font-2 bold">{{drawStats.parseDecimalesRia(stat.global.total)}}</h1>
          <div class="star">
            <p class="star-font" > {{drawStats.parseDecimales(stat.global.average)}} </p>
            <div *ngFor="let number of arrayStars"><img [src]='drawStats.getStar(stat.global.average,number)'></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="row">
    <div class="col-md p-3 mensual">{{'monthly' | translate}}</div>
    <div class="col-md p-3 " *ngFor="let stat of stats.typologies">
      <div class="row">
        <div class="col-md-12 typology-mobile">
          <img src="../assets/img/typology/ATENCION_negative.jpg" alt="">{{stat.name}}
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center background-color" >
          <h1 class="top-font-2 bold">{{drawStats.parseDecimalesRia(stat.month.total)}}</h1>
          <div class="star">
            <p class="star-font">{{drawStats.parseDecimales(stat.month.average)}}</p>
            <div *ngFor="let number of arrayStars"><img [src]='drawStats.getStar(stat.month.average,number)'></div></div>
          </div>
      </div>
    </div>
  </div>
</div>
</div>