import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
@Component({
  selector: 'app-login-token',
  templateUrl: './login-token.component.html',
  styleUrls: ['./login-token.component.scss']
})
export class LoginTokenComponent implements OnInit {

  token: string;

  constructor(
    public router: Router,
    private _route: ActivatedRoute) {
      this.token = '';
    }

  ngOnInit(): void {

    const token_url:any = this._route.snapshot.paramMap.get('token');

      localStorage.setItem('access_token', token_url);

      const token: any = localStorage.getItem('access_token')?? false;

      const decoded: any = jwt_decode(token);

      localStorage.setItem('companyId', decoded.emp);

    if (window.localStorage.getItem('access_token')){
      this.router.navigate(['/panel']);
  }
  }

}
