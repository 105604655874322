import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LangMonthService {
  constructor() { }
  langMonth(month:number,year:number,lang:any):any{
      if (month==1 && lang === 'es' ) return "Enero "+year;
      if (month==2 && lang === 'es'  ) return "Febrero "+year;
      if (month==3 && lang === 'es' ) return "Marzo "+year;
      if (month==4 && lang === 'es' ) return "Abril "+year;
      if (month==5 && lang === 'es' ) return "Mayo "+year;
      if (month==6 && lang === 'es' ) return "Junio "+year;
      if (month==7 && lang === 'es' ) return "Julio "+year;
      if (month==8 && lang === 'es' ) return "Agosto "+year;
      if (month==9 && lang === 'es' ) return "Septiembre "+year;
      if (month==10 && lang === 'es' ) return "Octubre "+year;
      if (month==11 && lang === 'es' ) return "Noviembre "+year;
      if (month==12 && lang === 'es' ) return "Diciembre "+year;
      if (month==1 && lang === 'en') return "January "+year;
      if (month==2 && lang === 'en') return "February "+year;
      if (month==3 && lang === 'en') return "March "+year;
      if (month==4 && lang === 'en') return "April "+year;
      if (month==5 && lang === 'en') return "May "+year;
      if (month==6 && lang === 'en') return "June "+year;
      if (month==7 && lang === 'en') return "July "+year;
      if (month==8 && lang === 'en') return "August "+year;
      if (month==9 && lang === 'en') return "September "+year;
      if (month==10 && lang === 'en') return "October "+year;
      if (month==11 && lang === 'en') return "November "+year;
      if (month==12 && lang === 'en') return "December "+year;

  }
  langMobile(lang: any): any{
    if (lang === 'es') return 'Móvil';
    if (lang === 'en') return 'Mobile';
    }

  langDesktop(lang: any): any{
    if (lang === 'es') return 'PC';
    if (lang === 'en') return 'Desktop';
  }
  langSearch(lang: any): any{
    if (lang === 'es') return 'Buscador';
    if (lang === 'en') return 'Search';
  }
  langMap(lang: any): any{
    if (lang === 'es') return 'Mapas';
    if (lang === 'en') return 'Maps';
  }
  langNeutralGlobal(lang: any): any{
    if (lang === 'es') return 'Neutras-Global';
    if (lang === 'en') return 'Neutral-Global';
  }
  langGoodGlobal(lang: any): any{
    if (lang === 'es') return 'Buenas-Global';
    if (lang === 'en') return 'Good-Global';
  }
  langBadGlobal(lang: any): any{
    if (lang === 'es') return 'Malas-Global';
    if (lang === 'en') return 'Bad-Global';
  }
  langNeutralMonthly(lang: any): any{
    if (lang === 'es') return 'Neutras-Mensual';
    if (lang === 'en') return 'Neutral-Monthly';
  }
  langBadMonthly(lang: any): any{
    if (lang === 'es') return 'Malas-Mensual';
    if (lang === 'en') return 'Bad-Monthly';
  }
  langGoodMonthly(lang: any): any{
    if (lang === 'es') return 'Buenas-Mensual';
    if (lang === 'en') return 'Good-Monthly';
  }
  langCalls(lang: any): any{
    if (lang === 'es') return 'Llamadas';
    if (lang === 'en') return 'Calls';
  }
  langGood(lang: any): any{
    if (lang === 'es') return 'Buenas';
    if (lang === 'en') return 'Positive';
  }
  langNeutra(lang: any): any{
    if (lang === 'es') return 'Neutras';
    if (lang === 'en') return 'Neutral';
  }
  langNegative(lang: any): any{
    if (lang === 'es') return 'Malas';
    if (lang === 'en') return 'Negative';
  }
  langTypologyMonthly(lang: any): any{
    if (lang === 'es') return 'MENSUAL';
    if (lang === 'en') return 'MONTHLY';
  }
  langTypology(name: any,lang: any): any{
    if (lang === 'es' && name === 'PRODUCTOS') return 'PRODUCTOS';
    if (lang === 'en' && name === 'PRODUCTOS') return 'PRODUCTS';
    if (lang === 'es' && name === 'ATENCIÓN') return 'ATENCIÓN';
    if (lang === 'en' && name === 'ATENCIÓN') return 'SUPPORT';
    if (lang === 'es' && name === 'PRECIO') return 'PRECIO';
    if (lang === 'en' && name === 'PRECIO') return 'PRICE';
    if (lang === 'es' && name === 'SIN TEXTO') return 'SIN TEXTO';
    if (lang === 'en' && name === 'SIN TEXTO') return 'NO TEXT';
    if (lang === 'es' && name === 'LOCAL') return 'LOCAL';
    if (lang === 'en' && name === 'LOCAL') return 'LOCATION';
    if (lang === 'es' && name === 'SC') return 'SC';
    if (lang === 'en' && name === 'SC') return 'NC';
    if (lang === 'es' && name === 'ESTRUCTURA') return 'ESTRUCTURA';
    if (lang === 'en' && name === 'ESTRUCTURA') return 'STRUCTURE';
    if (lang === 'es' && name === 'CALIDAD') return 'CALIDAD';
    if (lang === 'en' && name === 'CALIDAD') return 'QUALITY';
    if (lang === 'es' && name === 'TELEFONO') return 'TELEFONO';
    if (lang === 'en' && name === 'TELEFONO') return 'PHONE';
    if (lang === 'es' && name === 'TELÉFONO') return 'TELEFONO';
    if (lang === 'en' && name === 'TELÉFONO') return 'PHONE';
    if (lang === 'es' && name === 'TIEMPO') return 'TIEMPO';
    if (lang === 'en' && name === 'TIEMPO') return 'TIME';
    if (lang === 'es' && name === 'SERVICIO') return 'SERVICIO';
    if (lang === 'en' && name === 'SERVICIO') return 'ATTENTION';
    if (lang === 'es' && name === 'CAJERO') return 'CAJERO';
    if (lang === 'en' && name === 'CAJERO') return 'ATM';
    if (lang === 'es' && name === 'EMPLEADO EN CONCRETO') return 'EMPLEADO EN CONCRETO';
    if (lang === 'en' && name === 'EMPLEADO EN CONCRETO') return 'EMPLOYEE IN CONCRETE';
    if (lang === 'es' && name === 'COSAS') return 'COSAS';
    if (lang === 'en' && name === 'COSAS') return 'THINGS';
    if (lang === 'es' && name === 'CIERRE SUCURSALES') return 'CIERRE SUCURSALES';
    if (lang === 'en' && name === 'CIERRE SUCURSALES') return 'CLOSING OFFICES';
    if (lang === 'es' && name === 'HORARIO') return 'HORARIO';
    if (lang === 'en' && name === 'HORARIO') return 'SCHEDULE';
    if (lang === 'es' && name === 'BOLSAS') return 'BOLSAS';
    if (lang === 'en' && name === 'BOLSAS') return 'BAGS';
    if (lang === 'es' && name === 'PERSONAL') return 'PERSONAL';
    if (lang === 'en' && name === 'PERSONAL') return 'STAFF';
    if (lang === 'es' && name === 'EQUIPO') return 'EQUIPO';
    if (lang === 'en' && name === 'EQUIPO') return 'TEAM';
    if (lang === 'es' && name === 'ESTADIO') return 'ESTADIO';
    if (lang === 'en' && name === 'ESTADIO') return 'STADIUM';
    if (lang === 'es' && name === 'APARCAMIENTO') return 'APARCAMIENTO';
    if (lang === 'en' && name === 'APARCAMIENTO') return 'PARKING';
    if (lang === 'es' && name === 'AFICION') return 'AFICION';
    if (lang === 'en' && name === 'AFICION') return 'FANS';
    if (lang === 'es' && name === 'AFICIÓN') return 'AFICIÓN';
    if (lang === 'en' && name === 'AFICIÓN') return 'FANS';
    if (lang === 'es' && name === 'ENVIOS') return 'ENVIOS';
    if (lang === 'en' && name === 'ENVIOS') return 'SHIPMENTS';
    if (lang === 'es' && name === 'ENVÍOS') return 'ENVÍOS';
    if (lang === 'en' && name === 'ENVÍOS') return 'SHIPMENTS';
    if (lang === 'es' && name === 'PEÑAS') return 'PEÑAS';
    if (lang === 'en' && name === 'PEÑAS') return 'SUPPORTRS CLUBS';
    if (lang === 'es' && name === 'OTRO IDIOMA') return 'OTRO IDIOMA';
    if (lang === 'en' && name === 'OTRO IDIOMA') return 'OTHER LANGUAGE';
    if (lang === 'es' && name === 'AFICION') return 'AFICION';
    if (lang === 'en' && name === 'AFICION') return 'FANS';
    if (lang === 'es' && name === 'AFICIÓN') return 'AFICIÓN';
    if (lang === 'en' && name === 'AFICIÓN') return 'FANS';
    if (lang === 'es' && name === 'CESPED') return 'CESPED';
    if (lang === 'en' && name === 'CESPED') return 'GRASS';
    if (lang === 'es' && name === 'VESTUARIOS') return 'VESTUARIOS';
    if (lang === 'en' && name === 'VESTUARIOS') return 'CHANGING ROOMS';
    if (lang === 'es' && name === 'ACCESIBILIDAD') return 'ACCESIBILIDAD';
    if (lang === 'en' && name === 'ACCESIBILIDAD') return 'ACCESSIBILITY';
    if (lang === 'es' && name === 'EXPERIENCIA') return 'EXPERIENCIA';
    if (lang === 'en' && name === 'EXPERIENCIA') return 'EXPERIENCE';
    if (lang === 'es' && name === 'IDIOMA') return 'IDIOMA';
    if (lang === 'en' && name === 'IDIOMA') return 'LANGUAGE';
    if (lang === 'es' && name === 'PRUEBA TIPOLOGICA') return 'PRUEBA TIPOLOGICA';
    if (lang === 'en' && name === 'PRUEBA TIPOLOGICA') return 'TYPOLOGICAL TEST';
    if (lang === 'es' && name === 'PRUEBA') return 'PRUEBA';
    if (lang === 'en' && name === 'PRUEBA') return 'TEST';
    if (lang === 'es' && name === 'COMEPIPAS') return 'COMEPIPAS';
    if (lang === 'en' && name === 'COMEPIPAS') return 'COMEPIPAS';
    if (lang === 'es' && name === 'ATENCION') return 'ATENCION';
    if (lang === 'en' && name === 'ATENCION') return 'CUSTOMER SERVICE';
    if (lang === 'es' && name === 'INSTALACIONES') return 'INSTALACIONES';
    if (lang === 'en' && name === 'INSTALACIONES') return 'FACILITIES';
    if (lang === 'es' && name === 'SIN COMENTARIO') return 'SIN COMENTARIO';
    if (lang === 'en' && name === 'SIN COMENTARIO') return 'NO COMMENT';
    if (lang === 'es' && name === 'AFORO') return 'AFORO';
    if (lang === 'en' && name === 'AFORO') return 'CAPACITY';
    if (lang === 'es' && name === 'CENTRO COMERCIAL') return 'CENTRO COMERCIAL';
    if (lang === 'en' && name === 'CENTRO COMERCIAL') return 'MALL';
    if (lang === 'es' && name === 'CONDICIONES NO SOCIOS') return 'CONDICIONES NO SOCIOS';
    if (lang === 'en' && name === 'CONDICIONES NO SOCIOS') return 'CONDITIONS NOT PARTNERS';
    if (lang === 'es' && name === 'CONCIERTOS') return 'CONCIERTOS';
    if (lang === 'en' && name === 'CONCIERTOS') return 'CONCERTS';
    if (lang === 'es' && name === 'ENVIO DE DINERO') return 'ENVIO DE DINERO';
    if (lang === 'en' && name === 'ENVIO DE DINERO') return 'MONEY TRANSFER';
    if (lang === 'es' && name === 'CAMBIO DE DIVISAS') return 'CAMBIO DE DIVISAS';
    if (lang === 'en' && name === 'CAMBIO DE DIVISAS') return 'CURRENCY EXCHANGE';
    if (lang === 'es' && name === 'SERVICIO POSTVENTA') return 'SERVICIO POSTVENTA';
    if (lang === 'en' && name === 'SERVICIO POSTVENTA') return 'AFTER-SALES SERVICE';
    if (lang === 'es' && name === 'TALLER') return 'TALLER';
    if (lang === 'en' && name === 'TALLER') return 'WORKSHOP';
    if (lang === 'es' && name === 'PRODUCTO/SERVICIOS') return 'PRODUCTO/SERVICIOS';
    if (lang === 'en' && name === 'PRODUCTO/SERVICIOS') return 'PRODUCTO/SERVICE';
    if (lang === 'es' && name === 'PEDIDO') return 'PEDIDO';
    if (lang === 'en' && name === 'PEDIDO') return 'ORDER';
    if (lang === 'es' && name === 'MARCA') return 'MARCA';
    if (lang === 'en' && name === 'MARCA') return 'BRAND';
    if (lang === 'es' && name === 'LIBERTADORES') return 'LIBERTADORES';
    if (lang === 'en' && name === 'LIBERTADORES') return 'LIBERTADORES';
    if (lang === 'es' && name === 'ANTIMADRIDISTAS/TROLLS') return 'ANTIMADRIDISTAS/TROLLS';
    if (lang === 'en' && name === 'ANTIMADRIDISTAS/TROLLS') return 'ANTIMADRIDISTAS/TROLLS';
    if (lang === 'es' && name === 'COMEPIPAS') return 'COMEPIPAS';
    if (lang === 'en' && name === 'COMEPIPAS') return 'COMEPIPAS';
    if (lang === 'es' && name === 'CLUB') return 'CLUB';
    if (lang === 'en' && name === 'CLUB') return 'CLUB';
    if (lang === 'es' && name === 'CANCER') return 'CANCER';
    if (lang === 'en' && name === 'CANCER') return 'CANCER';
  }
}
