import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import { FilterService } from 'src/app/service/filter.service';
import { LangMonthService } from 'src/app/service/lang-month.service';
import { TipologiasService } from 'src/app/service/tipologias.service';
import { Analisis } from './analisis.model';
import {StatsAnualesService} from 'src/app/service/stats-anuales.service';
import { DrawStatsService} from 'src/app/service/draw-stats.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-analisis',
  templateUrl: './analisis.component.html',
  styleUrls: ['./analisis.component.scss']
})
export class AnalisisComponent implements OnInit {
  canvas: any;
  ctx: any;
  chartUpdate: any;
  newCanvas: any;
  divCanvas: any;
  stats: Analisis;
  date: string;
  editDate: string;
  drawStats: DrawStatsService;
  arrayStars: Array<number>;

  public customDateSubscription: Subscription;
  public customLocationSubscription: Subscription;
  public customLangSubscription: Subscription;

  constructor(
    public router: Router,
    public langMonthService: LangMonthService,
    public tipologiasService: TipologiasService,
    public statsAnualesService: StatsAnualesService,
    private drawStatsService: DrawStatsService,
    private filter: FilterService
    ) {
      if (!localStorage.getItem('access_token')){
        this.router.navigate(['']);
      }
      this.drawStats = new DrawStatsService();
      this.arrayStars = [1,2,3,4,5];
      this.stats = this.getStatsDefault();
  }

  ngOnInit(): void {
    this.customDateSubscription = this.filter.customDate.subscribe(date => {
      if (date!=='0'){
      this.drawStats.addSpinner();
      const newDate = new Date(date);
      const id = this.filter.getBrandCurrentValue();
      const type = this.filter.getTypeCurrentValue();
      const lang = this.filter.getLangCurrentValue();
      const idLocation = this.filter.getLocationCurrentValue();
      if(idLocation === '0'){
        this.tipologiasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillTipologias(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
      }else{
        this.statsAnualesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),idLocation).subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillTipologias(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
      }

      }
    });  

    this.customLocationSubscription = this.filter.customLocation.subscribe(locationId => {
      if (locationId!=='0'){
      const date: string = this.filter.getDateCurrentValue();
      const newDate = new Date(date);
      const lang = this.filter.getLangCurrentValue();
      const month = newDate.getMonth()+1
      const varLocation = 'location_'+locationId+'-'+month+'-'+newDate.getFullYear();
      this.statsAnualesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),locationId).subscribe((dataStats) => {
        if(dataStats.data === null){
          this.stats = this.getStatsDefault();
          this.createChart(lang);
          this.drawStats.removeSpinner();
        }else{
          if (localStorage.getItem(varLocation)){
            this.fillTipologiasLocations(dataStats);
            this.createChart(lang);
            setTimeout(()=>{
              this.drawStats.removeSpinner();
          }, 10);
          }else{
            localStorage.setItem(varLocation,JSON.stringify(dataStats));
            this.fillTipologiasLocations(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        }
      });
      }else{
        const date: string = this.filter.getDateCurrentValue();
        if (date!=='0'){
        const newDate = new Date(date);
        const id = this.filter.getBrandCurrentValue();
        const lang = this.filter.getLangCurrentValue();
        this.tipologiasService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillTipologias(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
      }
    }
    });
    this.customLangSubscription = this.filter.customLang.subscribe(lang =>{
      this.createChart(lang);
    });

  }
  ngOnDestroy(): void{
    this.customDateSubscription.unsubscribe();
    // this.customLangSubscription.unsubscribe();
    this.customLocationSubscription.unsubscribe();
  }

  createChart(lang: any){
    const labels = [];
    for( const i in this.stats.typologies){
      if (this.stats.typologies[i].show){
        labels.push('GLOBAL');
        labels.push(this.langMonthService.langTypologyMonthly(lang));
      }
    }
    this.canvas = document.getElementById('myChart');
    if(this.canvas){
      this.canvas.remove();
    }
    this.newCanvas = document.createElement('canvas');
    this.newCanvas.setAttribute('id', 'myChart');
    this.newCanvas.setAttribute('width', 600);
    this.newCanvas.setAttribute('height', 140);
    this.newCanvas.setAttribute('class', 'grafic');
    this.divCanvas = document.getElementById('canvas');
    this.divCanvas.appendChild(this.newCanvas);
    this.canvas = this.newCanvas;

    this.ctx = this.canvas.getContext('2d');
    const chartdata = this.dataChart(this.stats, lang);

    this.chartUpdate = new Chart(this.ctx, {
        type: 'bar',
        data: chartdata,
        options: {
          legend: {
            display: false,
        },
          scales: {
            xAxes:
            [{
              labels,
              stacked: true,
              ticks: {
                beginAtZero: true,
                maxRotation: 0,
                minRotation: 0
              }
            },
            {
              id: 'XAxis1',
              type: 'category',
              offset: true,
              gridLines: {
                offsetGridLines: true
              }
            }],
            yAxes:
              [
                {
                  position: 'left',
                  scaleLabel: {
                      display: true,
                      labelString: '%',
                      fontFamily: 'Roboto',
                      fontSize: 30,
                      fontColor: 'black',
                  },
                }
            ],
          },
      }
    });

  }

  dataChart(stats:any, lang: any){
    const labels = [];
    const dato_global_good =[];
    const dato_global_neutral =[];
    const dato_global_bad =[];
    const dato_month_good =[];
    const dato_month_bad =[];
    const dato_month_neutral =[];
    const labelNeutralGlobal = this.langMonthService.langNeutralGlobal(lang);
    const labelGoodGlobal  = this.langMonthService.langGoodGlobal(lang);
    const labelBadGlobal  = this.langMonthService.langBadGlobal(lang);
    const labelNeutralMonthly = this.langMonthService.langNeutralMonthly(lang);
    const labelGoodMonthly  = this.langMonthService.langGoodMonthly(lang);
    const labelBadMonthly  = this.langMonthService.langBadMonthly(lang);


    for (const i in stats.typologies){
      if (stats.typologies[i].show){
        labels.push(this.langMonthService.langTypology(stats.typologies[i].name, lang));
        dato_global_good.push(stats.typologies[i].global.good);
        dato_global_neutral.push(stats.typologies[i].global.neutral);
        dato_global_bad.push(stats.typologies[i].global.bad);
        dato_month_good.push(stats.typologies[i].month.good);
        dato_month_bad.push(stats.typologies[i].month.bad);
        dato_month_neutral.push(stats.typologies[i].month.neutral);
      }
    }
    const chartdata = {
      labels,
      datasets:[{
        type: 'bar',
        label: labelNeutralGlobal,
        xAxisID: 'XAxis1',
        data: dato_global_neutral,
        fill: true,
        backgroundColor: '#F7CFBD', //AMARILLO
        borderWidth: 1,
        borderColor: '#F7CFBD',
        hoverBackgroundColor: '#F7CFBD',
        hoverBorderColor: '#F7CFBD',
        stack: 'OB'
      },
      {
        type: 'bar',
        label: labelBadGlobal,
        xAxisID: 'XAxis1',
        data: dato_global_bad,
        fill: true,
        backgroundColor: '#e4e4e454', //ROSA
        borderWidth: 1,
        borderColor: '#e4e4e454',
        hoverBackgroundColor: '#e4e4e454',
        hoverBorderColor: '#e4e4e454',
        stack: 'OB'
      },{
        type: 'bar',
        label: labelGoodGlobal,
        xAxisID: 'XAxis1',
        data: dato_global_good,
        fill: true,
        backgroundColor: '#FF6100', //VERDE
        borderWidth: 1,
        borderColor: ' #FF6100',
        hoverBackgroundColor: ' #FF6100',
        hoverBorderColor: ' #FF6100',
        stack: 'OB'
      },{
        type: 'bar',
        label: labelNeutralMonthly,
        xAxisID: 'XAxis1',
        data: dato_month_neutral,
        fill: true,
        backgroundColor: '#F7CFBD', //AMARILLO
        borderWidth: 1,
        borderColor: '#F7CFBD',
        hoverBackgroundColor: '#F7CFBD',
        hoverBorderColor: '#F7CFBD',
        stack: 'Income'
      },
      {
        type: 'bar',
        label: labelBadMonthly,
        xAxisID: 'XAxis1',
        data: dato_month_bad,
        fill: true,
        backgroundColor: '#e4e4e454', //ROSA
        borderWidth: 1,
        borderColor: '#e4e4e454',
        hoverBackgroundColor: '#e4e4e454',
        hoverBorderColor: '#e4e4e454',
        stack: 'Income'
      },{
        type: 'bar',
        label: labelGoodMonthly,
        xAxisID: 'XAxis1',
        data: dato_month_good,
        fill: true,
        backgroundColor: '#FF6100', //VERDE
        borderWidth: 1,
        borderColor: ' #FF6100',
        hoverBackgroundColor: ' #FF6100',
        hoverBorderColor: '#FF6100',
        stack: 'Income'
      }
      ] 
    }
    return chartdata;
  }
  
  fillTipologias(dataStats:any){
    const typologies = [];
    
    for (const i in dataStats.data.typologies){
      const newTypology = {
          name: dataStats.data.typologies[i].name,
          show: dataStats.data.typologies[i].show,
          global: {
            total: dataStats.data.typologies[i].global.total,
            average: dataStats.data.typologies[i].global.average.replace(/,/g, '.'),
            good: dataStats.data.typologies[i].global.good,
            neutral: dataStats.data.typologies[i].global.neutral,
            bad: dataStats.data.typologies[i].global.bad,
          },
          month: {
            total: dataStats.data.typologies[i].month.total,
            average: dataStats.data.typologies[i].month.average.replace(/,/g, '.'),
            good: dataStats.data.typologies[i].month.good,
            neutral: dataStats.data.typologies[i].month.neutral,
            bad: dataStats.data.typologies[i].month.bad,
          },
        };
      typologies.push(newTypology);
    }
    this.stats = { typologies };
  }

  fillTipologiasLocations(dataStats:any){
    const keyDataWordGraph = Object.keys(dataStats.data['typology-data-graph'].dataGlobal)
    const globalData = dataStats.data['typology-data-graph'];
    const monthData = dataStats.data['typology-data-graph'].dataMonth
    const typologies = [];
    for (const i in keyDataWordGraph){
      // console.log(globalData.dataGlobal[keyDataWordGraph[i]],"LINEA 315")
      // console.log(monthData[keyDataWordGraph[i]],"datamonth")
      
      const newTypology = {
          name: keyDataWordGraph[i],
          show: null,
          global: {
            total: globalData.dataGlobal[keyDataWordGraph[i]].total,
            average: '',
            good: globalData.dataGlobal[keyDataWordGraph[i]].positive,
            neutral: globalData.dataGlobal[keyDataWordGraph[i]].neutral,
            bad: globalData.dataGlobal[keyDataWordGraph[i]].negative,
          },
          month: {
            total: monthData[keyDataWordGraph[i]].total,
            average: '',
            good: monthData[keyDataWordGraph[i]].positive,
            neutral: monthData[keyDataWordGraph[i]].neutral,
            bad: monthData[keyDataWordGraph[i]].negative,
          },
        };
      typologies.push(newTypology);
    }

    this.stats = { typologies };
  }

  getStatsDefault(){
    return{
      typologies:[
        {
          name: '',
          show: false,
          global: {
            total: 0,
            average: 0,
            good: 0,
            neutral: 0,
            bad: 0,
          },
          month: {
            total: 0,
            average: 0,
            good: 0,
            neutral: 0,
            bad: 0,
          }
        },
        {
          name: '',
          show: false,
          global: {
            total: 0,
            average: 0,
            good: 0,
            neutral: 0,
            bad: 0,
          },
          month: {
            total: 0,
            average: 0,
            good: 0,
            neutral: 0,
            bad: 0,
          }
        },
        {
          name: '',
          show: false,
          global: {
            total: 0,
            average: 0,
            good: 0,
            neutral: 0,
            bad: 0,
          },
          month: {
            total: 0,
            average: 0,
            good: 0,
            neutral: 0,
            bad: 0,
          }
        },
        {
          name: '',
          show: false,
          global: {
            total: 0,
            average: 0,
            good: 0,
            neutral: 0,
            bad: 0,
          },
          month: {
            total: 0,
            average: 0,
            good: 0,
            neutral: 0,
            bad: 0,
          }
        },{
          name: '',
          show: false,
          global: {
            total: 0,
            average: 0,
            good: 0,
            neutral: 0,
            bad: 0,
          },
          month: {
            total: 0,
            average: 0,
            good: 0,
            neutral: 0,
            bad: 0,
          }
        }

      ]
    };
  }
}
