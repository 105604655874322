import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BusquedasService } from 'src/app/service/busquedas.service';
import { LangMonthService } from 'src/app/service/lang-month.service';
import { FilterService } from 'src/app/service/filter.service';
import { CompanyService } from 'src/app/service/company.service';
import jwt_decode from 'jwt-decode';
import { DrawStatsService} from 'src/app/service/draw-stats.service';
import * as dayjs from 'dayjs';
import { TranslateService } from '@ngx-translate/core';
import { OptionDateInput } from './navegador.model';
import {StickerService} from 'src/app/service/sticker.service';
import {startWith, map} from 'rxjs/operators';
import {FormControl} from '@angular/forms';
import { Observable } from 'rxjs';
/* eslint-disable */

export interface Location {
  _id: string;
  codigo_tienda: string;
  label: string;
  direccion: string;
  maps: string;
  nombre: string;
}

@Component({
  selector: 'app-navegador',
  templateUrl: './navegador.component.html',
  styleUrls: ['./navegador.component.scss']
})
export class NavegadorComponent implements OnInit {
  public name: string;
  public optionDateSelect: string;
  public date: string;
  public drawStats: DrawStatsService;
  public selectDates: Array<{name: string; year: number; value: string}>;
  public company: {_id: string; nombre: string};
  public sticker: {_id: string; name: string};
  public brands: Array<{_id: string; nombre: string}>;
  public stickers: Array<{_id: string; name: string}>;
  public locationsIds: Location[] = [{_id: '0', codigo_tienda: 'Type your location', direccion:'', maps:'', nombre: '', label:''}];
  public myControlLocations = new FormControl();
  public filteredOptions: Observable<Location[]>;
  public optionCompanySelect: string;
  public optionBrandSelect: string;
  public optionStickerSelect: string;
  public optionLocationsSelect: any;
  public optionLocationObjectSelect: any;
  public optionLangSelect: any;
  public activeLang = 'en';
  private months: string[] = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
    'Agosto', 'Septiembre', 'Octubre', 'Noviembre','Diciembre'
  ];
  private now: dayjs.Dayjs = dayjs().subtract(1, 'month');
  constructor(
    public router: Router,
    public busquedasService: BusquedasService,
    public langMonthService: LangMonthService,
    public companyService: CompanyService,
    public stickerService: StickerService,
    public filter: FilterService,
    public drawStatsService: DrawStatsService,
    public translate: TranslateService
    ) {
    this.drawStats = new DrawStatsService();
    this.name = '';
    this.selectDates = this.getSelectDatesInfo();
    this.company = {_id: '0', nombre: ''};
    this.sticker = {_id: '0', name: ''};
    this.brands = [{_id: '0', nombre: ''}];
    this.stickers = [{_id: '0', name: ''}];
    this.locationsIds = [{_id: '0', codigo_tienda: 'Type your location', direccion:'', maps:'', nombre: '', label:''}];
    this.optionCompanySelect = '';
    this.optionBrandSelect = '';
    this.optionStickerSelect = '';
    this.optionLocationsSelect = '';
    this.optionLocationObjectSelect = '';
    this.optionDateSelect = this.funcionMinorDateEight();
    this.optionLangSelect = this.translate.setDefaultLang(this.activeLang);
    }

  ngOnInit(): void {
    if(this.date === '0'){
      const dateCompare = dayjs().subtract(1, 'month').format('DD');
      if (dateCompare < '08'){
        const now: dayjs.Dayjs = dayjs().subtract(2, 'month');
        this.optionDateSelect = now.format('YYYY-MM-DD');
        now.format('YYYY-MM-DD');
      }else{
        const now: dayjs.Dayjs = dayjs().subtract(1, 'month');
        this.optionDateSelect = now.format('YYYY-MM-DD');
        now.format('YYYY-MM-DD');
      }
    }
    const month = this.captureDateSelect();
    // const newDate = new Date(month);
    this.filter.customDate.subscribe((date) =>{
      this.date = date;
    });

    this.filter.customLocationObject.subscribe((date) =>{
      this.optionLocationObjectSelect = date;
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const token: string = localStorage.getItem('access_token')!;
    const decoded: any = jwt_decode(token);
    this.companyService.getCompanyLogged().subscribe((data) => {
      this.company = data;
      this.stickers = data.stickers;
      this.optionStickerSelect = this.filter.getStickerCurrentValue();
      this.optionBrandSelect  = this.filter.getBrandCurrentValue();
      this.optionLangSelect = this.filter.getLangCurrentValue();
      this.companyService.getBrandsByCompanyLogged().subscribe((brands) => {
        this.brands = brands;
      });
    });

    this.filteredOptions = this.myControlLocations.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value._id)),
      map(_id => (_id ? this._filter(_id) : this.locationsIds.slice() )),
    );

    this.stickerService.getStickerLogged().subscribe((data) => {
      this.sticker = data;
      this.optionLangSelect = this.filter.getLangCurrentValue();
      this.optionLocationsSelect = this.filter.getLocationCurrentValue();
      this.drawStats.addSpinner();
      this.stickerService.postStickersId().then(res => {
        const resi = res as any;
        for(const i in resi){
          let labelName = ''; 
          if(resi[i].label !== undefined){labelName = resi[i].label}
            const newObject={
              _id: resi[i]._id,
              codigo_tienda: resi[i].codigo_tienda,
              direccion: resi[i].direccion,
              maps: resi[i].url_reviews,
              nombre: resi[i].nombre,
              label: labelName
            };
            this.locationsIds.push(newObject);
            this.drawStats.removeSpinner();
          
        }
        
      });
    });

  }

  public displayFn(location: Location): string {

    if (location !== null && location.label !=='' &&  location.label !== undefined ){
      return location && location.nombre ? location.codigo_tienda + ' - ' + location.label : '';
    }else{
      return location && location.nombre ? location.codigo_tienda + ' - ' + location.direccion : '';
    }
  }

  private _filter(codigo: string): Location[] {
    const filterValue = codigo.toLowerCase();
    // eslint-disable-next-line max-len
    return this.locationsIds.filter(option => option.codigo_tienda.toLowerCase().includes(filterValue) || option.label.toLowerCase().includes(filterValue) || option.direccion.toLowerCase().includes(filterValue) );
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  public cambiarLenguaje(lang: any) {
    this.activeLang = lang;
    this.translate.use(lang);
    this.captureLangSelect(lang);
  }
  captureDateSelect() {
      const month = this.optionDateSelect;
      this.filter.changeDate(month);
      return month;
  }
  funcionMinorDateEight(){
    this.filter.customDate.subscribe((date) =>{
      this.date = date;
    });
    return this.date;
  }

  captureCompanySelect() {
    const company = this.optionCompanySelect;
    this.filter.changeCompany(company);
    return company;
  }

  captureBrandSelect() {
    const brand = this.optionBrandSelect;
    this.optionStickerSelect='0';
    this.filter.changeBrand(brand);
    this.filter.changeSticker('0');
    return brand;
  }
  captureStickerSelect(){
    const sticker = this.optionStickerSelect;
    this.optionBrandSelect = '0';
    this.filter.changeSticker(sticker);
    this.filter.changeBrand('0');
    return sticker;
  }
  captureLocationSelect(location: any){
    this.optionLocationsSelect = location;
    const locationId = location._id;
    this.drawStats.addSpinner();
    this.filter.changeLocation(locationId);
    this.filter.changeLocationObject(location);
    return locationId;
  }
  captureLangSelect(lang: any){
    this.filter.changeLang(lang);
    return lang;
  }

  logOut(){
    localStorage.clear();
    this.router.navigate(['']);
    localStorage.setItem("pageReloaded","off")
  }
  goMap(){
    if (!localStorage.getItem('access_token')){
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded","off")
    }else{
    this.router.navigate(['mapas']);
    }
  }
  goHistory(){
    if (!localStorage.getItem('access_token')){
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded","off")
    }else{
    this.router.navigate(['historico']);
    }
  }
  returnToPanel(){
    if (!localStorage.getItem('access_token')){
      this.router.navigate(['']);
      localStorage.setItem("pageReloaded","off")
    }else{
    this.router.navigate(['panel']);
    }
  };
  goToGoogleMaps(data: any){
    for(const i in data){
      if(data[i]._id === this.filter.getLocationCurrentValue()){
        const url = data[i].maps;
        window.location.href= url;
      }
    }
  }
  

  // ulrGoogle(url:any){
  //   console.log(url,"esa es la url");
  //   return url
  // }

  getSelectDatesInfo(){
    const dateInfo = [];
    for(let i = 1; i<=12; i++) {
      const dayJsDataMonth = dayjs().subtract(i, 'month');
      dateInfo.push({
        name: this.months[dayJsDataMonth.month()],
        year: dayJsDataMonth.format('YYYY'),
        value: dayJsDataMonth.format('YYYY-MM-DD'),
      });
    }
    const dateCompareDay = dayjs().subtract(1, 'month').format('DD');
    if (dateCompareDay < '08' ){
      dateInfo.shift();
    }
    return dateInfo;
  }
}

