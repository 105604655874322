import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Chart from 'chart.js';
import { AccionesService } from 'src/app/service/acciones.service';
import { FilterService } from 'src/app/service/filter.service';
import { LangMonthService } from 'src/app/service/lang-month.service';
import { Acciones } from './acciones.model';
import {StatsAnualesService} from 'src/app/service/stats-anuales.service';
import { DrawStatsService} from 'src/app/service/draw-stats.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-acciones',
  templateUrl: './acciones.component.html',
  styleUrls: ['./acciones.component.scss']
})
export class AccionesComponent implements OnInit {
  canvas: any;
  newCanvas: any;
  chartUpdate: any;
  divCanvas: any;
  ctx: any;
  stats: Acciones;
  date: string;
  editDate: string;
  drawStats: DrawStatsService;
  public customBrandSubscription: Subscription;
  public customDateSubscription: Subscription;
  public customLocationSubscription: Subscription;
  public customStickerSubscription: Subscription;
  public customLangSubscription: Subscription;

  constructor(
    public router: Router,
    public langMonthService: LangMonthService,
    public statsAnualesService: StatsAnualesService,
    public accionesService: AccionesService,
    private drawStatsService: DrawStatsService,
    public filter: FilterService
    ) {
      if (!localStorage.getItem('access_token')){
        this.router.navigate(['']);
      }
      this.drawStats = new DrawStatsService();
      this.stats = this.getStatsDefault();
  }
  ngOnInit(): void {
    this.customDateSubscription = this.filter.customDate.subscribe(date => {
      if (date!=='0'){
      this.drawStats.addSpinner();
      const newDate = new Date(date);
      const id = this.filter.getBrandCurrentValue();
      const type = this.filter.getTypeCurrentValue();
      const lang = this.filter.getLangCurrentValue();
      const idLocation = this.filter.getLocationCurrentValue();
      if(idLocation === '0'){
        this.accionesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillAcciones(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
      }else{
        this.statsAnualesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),idLocation).subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }else{
            this.fillAccionesLocations(dataStats);
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }
        });
      }
      }
    });
    this.customLocationSubscription = this.filter.customLocation.subscribe(locationId => {
      if (locationId!=='0'){
        const date: string = this.filter.getDateCurrentValue();
        const newDate = new Date(date);
        const lang = this.filter.getLangCurrentValue();
        const month = newDate.getMonth()+1;
        const varLocation = 'location_'+locationId+'-'+month+'-'+newDate.getFullYear();
        this.statsAnualesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),locationId).subscribe((dataStats) => {
          if(dataStats.data === null){
            this.stats = this.getStatsDefault();
            this.createChart(lang);
            this.drawStats.removeSpinner();
          }else{
              if (localStorage.getItem(varLocation)){
                this.fillAccionesLocations(dataStats);
                this.createChart(lang);
                setTimeout(()=>{
                  this.drawStats.removeSpinner();
              }, 10);
              }else{
                localStorage.setItem(varLocation,JSON.stringify(dataStats));
                this.fillAccionesLocations(dataStats);
                this.createChart(lang);
                this.drawStats.removeSpinner();
              }
          }
        });
      }else{
        const date:string = this.filter.getDateCurrentValue();
        if (date!=='0'){
            const newDate = new Date(date);
            const id = this.filter.getBrandCurrentValue();
            const lang = this.filter.getLangCurrentValue();
            this.accionesService.getInfo(newDate.getMonth()+1,newDate.getFullYear(),id,'sticker').subscribe((dataStats) => {
              if(dataStats.data === null){
                this.stats = this.getStatsDefault();
                this.createChart(lang);
                this.drawStats.removeSpinner();
              }else{
                this.fillAcciones(dataStats);
                this.createChart(lang);
                this.drawStats.removeSpinner();
              }
            });
      }
    }
    });
    this.customLangSubscription = this.filter.customLang.subscribe(lang =>{
      this.createChart(lang);
    });
  }
  ngOnDestroy(): void{
    // this.customBrandSubscription.unsubscribe();
    this.customDateSubscription.unsubscribe();
    this.customLocationSubscription.unsubscribe();
    // this.customStickerSubscription.unsubscribe();
    this.customLangSubscription.unsubscribe();
  }
    createChart(lang:any){
      this.canvas = document.getElementById('myChart');
      if(this.canvas){
        this.canvas.remove();
      }
      this.newCanvas = document.createElement('canvas');
      this.newCanvas.setAttribute('id', 'myChart');
      this.newCanvas.setAttribute('width', 620);
      this.newCanvas.setAttribute('height', 400);
      this.newCanvas.setAttribute('class', 'grafic');
      this.divCanvas = document.getElementById('canvas3');
      this.divCanvas.appendChild(this.newCanvas);
      this.canvas = this.newCanvas;

      this.ctx = this.canvas.getContext('2d');
      const chartdata = this.createDataChart(this.stats, lang);

      new Chart(this.ctx, {
        type: 'bar',
        data: chartdata,
        options: {
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true }]
          }
        }
      });

    }

     createDataChart(stats:any, lang: any){
      const web  = [];
      const gps = [];
      const calls =[];
      const month: any =[];
      const labelCalls = this.langMonthService.langCalls(lang);
      for (const i in stats.historical){
        web.push(stats.historical[i].web);
        gps.push(stats.historical[i].gps);
        calls.push(stats.historical[i].calls);
        month.push(this.langMonthService.langMonth(stats.historical[i].month,stats.historical[i].year, lang));
      };
      const chartdata = {
        labels: month,
        datasets: [
          // {
          //   label: 'Web',
          //   data: web,
          //   backgroundColor: '#CDE1E1' 
          // },
          {
            label:'GPS',
            data: gps,
            backgroundColor: '#FAD2BE'
          },
          {
            label:labelCalls,
            data: calls,
            backgroundColor: '#FF6100'
          }
        ]
      };
      return chartdata;
    }
    fillAcciones(data:any){
      const historical =[] as any;
      const table = [] as any;
      this.stats.month = data.data.actions.month;
      this.stats.month_percent = data.data.actions.month_percent;
      this.stats.average_location = data.data.actions.average_location;
      this.stats.month_year_last_percent = data.data.actions.month_year_last_percent;
      this.stats.web = data.data.actions.web;
      this.stats.web_percent = data.data.actions.web_percent;
      this.stats.gps = data.data.actions.gps;
      this.stats.gps_percent = data.data.actions.gps_percent;
      this.stats.calls = data.data.actions.calls;
      this.stats.calls_percent = data.data.actions.calls_percent;
      this.stats.historical = historical;
        for (const i in data.data.actions.historical){
          const newHistorical={
            month: data.data.actions.historical[i].month,
            year: data.data.actions.historical[i].year,
            web: data.data.actions.historical[i].web.replaceAll('.',''),
            gps: data.data.actions.historical[i].gps.replaceAll('.',''),
            calls: data.data.actions.historical[i].calls.replaceAll('.',''),
          }
          historical.push(newHistorical);
        }
      this.stats.table = table;
        for(const i in data.data.actions.table){
          const newTable={
            ranking: data.data.actions.table[i].ranking,
            code: data.data.actions.table[i].code,
            description: data.data.actions.table[i].description,
            total: data.data.actions.table[i].total,
            web: data.data.actions.table[i].web,
            gps: data.data.actions.table[i].gps,
            call: data.data.actions.table[i].calls
          };
          table.push(newTable);
        }
    }
  fillAccionesLocations(data: any){
    const historical =[] as any;
    const table = [] as any;
    this.stats.month = data.data['actions-average-month'].formatted;
    this.stats.month_percent = this.fixedNumbers(data.data['actions-compare-last-month'].original);
    this.stats.average_location = this.fixedNumbers(data.data['actions-compare-last-year'].original);
    this.stats.month_year_last_percent = this.fixedNumbers(data.data['actions-compare-last-year'].original);
    this.stats.web = data.data['actions-web-month'].formatted;
    this.stats.web_percent = this.fixedNumbers(data.data['actions-web-compare-last-month'].original);
    this.stats.gps = data.data['actions-direction-month'].formatted;
    this.stats.gps_percent = this.fixedNumbers(data.data['actions-direction-compare-last-month'].original);
    this.stats.calls = data.data['actions-phone-month'].formatted;
    this.stats.calls_percent = this.fixedNumbers(data.data['actions-phone-compare-last-month'].original);
    this.stats.historical = historical;


      const arrayFechas = Object.keys(data.data['actions-data-graph'].data);
      const dataDirecteIndirect = data.data['actions-data-graph'].data;
      for(const n in arrayFechas){
        const fechaEspecifica = arrayFechas[n];
        const dataFechas = fechaEspecifica.split('-');   
        const newHistorical={
          month: dataFechas[1],
          year: dataFechas[0],
          web: dataDirecteIndirect[fechaEspecifica].web.formatted,
          gps: dataDirecteIndirect[fechaEspecifica].direction.formatted,
          calls: dataDirecteIndirect[fechaEspecifica].phone.formatted
        };
        historical.push(newHistorical);
      }
      this.stats.table = null;
  }

  fixedNumbers(data: any){
    const numberFixed = data*100;
    return numberFixed.toFixed(2);
  }

  getStatsDefault(){
   return {
    month:0,
    month_percent:0,
    average_location:0,
    month_year_last_percent:0,
    web: 0,
    web_percent: 0,
    gps: 0,
    gps_percent: 0,
    calls: 0,
    calls_percent: 0,
    historical:
    [
      {
        month:0,
        year:0,
        web:0,
        gps:0,
        calls:0,
      },
      {
        month:0,
        year:0,
        web:0,
        gps:0,
        calls:0,
      },
    ],
    table:
    [
      {
        ranking:0,
        code: 0,
        description: '',
        total:0,
        web:0,
        gps:0,
        call:0
      },
      {
        ranking:0,
        code: 0,
        description: '',
        total:0,
        web:0,
        gps:0,
        call:0
      },
    ]
    };
  }
}
