import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {of} from 'rxjs';
import {topConnect} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StatsAnualesService {

  constructor(private http: HttpClient) { }

  public getInfo(month: number,year: number,idLocation: string): Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      //'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    const idCompany = localStorage.getItem('companyId') ?? false;
    const varLocation = 'location_'+idLocation+'-'+month+'-'+year;
    if (localStorage.getItem(varLocation)){
      const data: any = JSON.parse(localStorage.getItem(varLocation));
      return of(data);
    }else{
      return this.http.get<any>(`${topConnect.backUrl}/info/localidad/${idLocation}/${year}/${month}/${idCompany}/`,{headers});
    }
  }
}
