import { Injectable } from '@angular/core';
// import { historico } from '../components/historico/historico.model';

@Injectable({
  providedIn: 'root'
})
export class DrawStatsService {
  s: any;
  star: any;
  constructor() { }

  public getClassByNumber(numberOfData: number){
    if (numberOfData > 0){
      return 'positive';
    }else{
      return 'negative';
    }
  }
  public cleanTildes(text: string){
    const s = text;
    const r= s.toUpperCase();
    const map = {
      // '-' : ' ',
      // '-' : '_',
      a : 'á|à|ã|â|À|Á|Ã|Â',
      e : 'é|è|ê|É|È|Ê',
      i : 'í|ì|î|Í|Ì|Î',
      o : 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
      u : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
      c : 'ç|Ç',
      n : 'ñ|Ñ'
  };

  // for (var pattern in map) {
  //     //  r = r.replace(new RegExp(map[pattern], 'g'), pattern);
  // };

    return r;
};

  public changeTypology(text: string){
      const name = text;
      // if (text=="PRODUCTOS") name = "PRODUCTO";
      return name;
  }

  public cleanNameByTypology(text: string){
      let nameTypology = text;
      nameTypology = this.changeTypology(nameTypology);
      nameTypology = this.cleanTildes(nameTypology);

      return nameTypology;
  }

  public getIcon(name:string, numberOfData:any){
    const number1 = parseFloat(numberOfData);
    name = this.cleanNameByTypology(name);
    if (number1  < 3){
      return `../assets/img/typology/${name}_negative.png`;
    }else if ( number1 >= 3 && number1  <4){
      return `../assets/img/typology/${name}_neutral.png`;
    }else{
      return `../assets/img/typology/${name}_positive.png`;
    }
  }

    public getStar(numberOfData: number, i: number): any{
        if ( i <= numberOfData ){
          return `../assets/img/star-small-grey.png`;
        } else {
          return `../assets/img/star-small-unfill-grey.png`;
        }
    }

    public getStarPanel(numberOfData: number, i: number): any{

      if ( i <= numberOfData ){
        return `../assets/img/_star-small.png`;
      } else {
        return `../assets/img/star-small-unfill.png`;
      }
    }

    public getColor(numberOfData: number){
      if (numberOfData < 3){
        return 'table-color-bad';
      }else if ( numberOfData >= 3 && numberOfData < 4){
        return 'table-color-neutral';
      }else{
        return 'table-color-good';
      }
    }
    public getColorAverage(numberOfData: number){
      if (numberOfData < 3){
        return 'average-color-bad';
      }else if ( numberOfData >= 3 && numberOfData < 4){
        return 'average-color-neutral';
      }else{
        return 'average-color-good';
      }
    }

    public parseDecimales(score: number){
      let numberOfScore = '';
      if (score){
        numberOfScore = score.toString();
      }
      return numberOfScore.replace(',', '.');
    }

    public parseDecimalesRia(score: number){
      let numberOfScore = '';
      if (score){
        numberOfScore = score.toString();
      }
      const re = /\./gi;
      return numberOfScore.replace(re, ',');
    }

    public getClassByRouter(url: string){
      if (url === '/panel'){
        return 'hidden';
      }else{
        return 'visible';
      }
    }

    public getClassBylocation(data: any){
      if (data === null){
        return 'hidden';
      }else{
        return 'visible';
      }
    }

    public getClassByLocationCenterGraphic(data: any){
      if (data === null){
        return '';
      }else{
        return 'col-md-6';
      }
    }

    public getClassButtonLocation(data: any){
      if (data === '0'){
        return 'hidden';
      }else{
        return 'visible';
      }
    }

    public getColorHistory(name: string,data: number,index: number, historico: any){
      if (historico[index+1] !== undefined){
        if(eval('historico[index].'+name) === eval('historico[index+1].'+name)){
          return 'neutral';
        }else if (eval('historico[index].'+name)> eval('historico[index+1].'+name)){
          return 'high';
        }else{
          return 'low';
        }
      }else{
        return 'neutral';
      }
    }

    public getPercentage(name: string,index: number,historico: any){
      if (historico[index+1] !== undefined){
        const valActual = eval('historico[index].'+name);
        const valAnterior = eval('historico[index+1].'+name);
        const percentage = (valActual -valAnterior)/ valAnterior;
        const percentageb = percentage * 100;
        const percentageFinal = percentageb.toFixed();
        return percentageFinal;
      }else{
        return '0';
      }
    }
    public getLanguageEn(lang: string){
      if(lang === 'en') {
        return 'hidden';
      }else{
        return 'visible';
      }
    }
    public getLanguageEs(lang: string){
      if(lang === 'es') {
        return 'hidden';
      }else{
        return 'visible';
      }
    }
    public addSpinner() {
      const cortina: any = document.getElementById('cortina');
      const spinner: any = document.getElementById('loaderSpinner');
      cortina.classList.add('cortina');
      spinner.classList.remove('hidden');
    }
    public removeSpinner(){
      const spinner: any = document.getElementById('loaderSpinner');
      const cortina: any = document.getElementById('cortina');
      spinner.classList.add('hidden');
      cortina.classList.remove('cortina');
    }
}

