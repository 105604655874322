
  <div class="row body">
      <div class="form">
        <form class="box" method="post">
          <img class="logImg" src="assets/img/riaLogo.png" alt="logo">
          <input type="email" [(ngModel)]="email" name="email" placeholder="Email" required="required"/>
          <input type="password" [(ngModel)]="password" name="password" placeholder="Contraseña" required="required"/>
          <div *ngIf="showErrorMessage">
            <p class="error-login">Wrong email or password</p>
          </div>
          <button class="" type="submit" (click)="onSubmitLogin()">Log In</button>
        </form>
        <h1 class="textLogin">Google My Business Dashboard</h1>
      </div>
  </div>

