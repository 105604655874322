<div class="cortina" id="cortina">
    <span class="spinner hidden" id="loaderSpinner"></span>
    <app-navegador></app-navegador>
    <div class="container">
        <p class="glossary">{{'glossary' | translate}}</p>
        <div class="divActivityGeneral">
            <div class="col-md-12 activityDiv">
                <p class="text-center">{{'activity' | translate}}</p>
            </div>
            <div class="col-md-3 text-center optionDiv">
                <div>
                    <p class="titleActivities">{{'impressions' | translate}}</p>
                </div>
                <div>
                    <p>{{'pImpressions' | translate}}</p>
                </div>
                <div>
                    <p>● {{'pImpressionsSearch' | translate}}</p>
                    <p>● {{'pImpressionsMap' | translate}}</p>
                </div>
            </div>
            <div class="col-md-3 text-center optionDiv">
                <div>
                    <p class="titleActivities">{{'searchs' | translate}}</p>
                </div>
                <div>
                    <p>{{'pSearchs' | translate}}</p>
                </div>
                <div>
                    <p>● {{'directpSearchs' | translate}}</p>
                    <p>● {{'indirectpSearchs' | translate}}</p>
                </div>
            </div>
            <div class="col-md-3 text-center optionDiv">
                <div>
                    <p class="titleActivities">{{'actions' | translate}}</p>
                </div>
                <div>
                    <p>{{'pActions' | translate}}</p>
                </div>
                <div>
                    <p>● {{'pActionsGps' | translate}}</p>
                    <p>● {{'pActionsCalls' | translate}}</p>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <p class="text-center activityDiv">{{'h1Reputation' | translate}}</p>
            <div class="col-md-12 text-center textReputation">
                <div>
                    <p>{{'p1Reputation' | translate}}</p>
                </div>
                <div>
                    <p>{{'p2Reputation' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
